import React from "react";
import {
    Heading,
    Portal,
    Button,
    Avatar,
    Menu,
    MenuButton,
    IconButton,
    MenuItem,
    MenuList,
    MenuDivider,
    useInterval
} from "@chakra-ui/react";
import { Link, navigate } from "gatsby";
import { MdPerson } from "react-icons/md";

import { useHasMounted } from "../components/hooks";

import getFirebase from "../utils/firebase";
import UserMgr from "../utils/user_manager";
import { isMobile } from "../utils/utility_funcs";
const UMObj = UserMgr.getInstance();


const User = () => {
    const [authModule, setAuthModule] = React.useState(null);
    const [auth, setAuth] = React.useState(null);
    const [user, setUser] = React.useState(null);

    const hasMounted = useHasMounted();
    if (hasMounted) {
        var fbImportPromises = [];
        fbImportPromises.push(import("firebase/app"));
        fbImportPromises.push(import("firebase/auth"));
        fbImportPromises.push(import("firebase/analytics"));
      
        Promise.all(fbImportPromises)
        .then(([fbAppModule, fbAuthModule, fbAnalyticsModule]) => {
            const fb = getFirebase(fbAppModule);
            const fbApp = fb.getApp();
            const auth = fbAuthModule.getAuth(fbApp);
            fbAuthModule.useDeviceLanguage(auth);
            fbAuthModule.onAuthStateChanged(auth, UMObj.AuthListener);
            fbAuthModule.onIdTokenChanged(auth, UMObj.AuthListener);
            const analytics = fbAnalyticsModule.getAnalytics(fbApp);
            UMObj.SetAnalytics(analytics, fbAnalyticsModule);

            setAuthModule(fbAuthModule);
            setAuth(auth);
            setUser(UMObj.GetCurrentUser());
        })
    }

    useInterval(() => {
        setUser(UMObj.GetCurrentUser());
    }, 500);

    const logoutHandler = () => {
        console.log(authModule)
        authModule.signOut(auth)
        setAuth(null);
        setUser(null);
        navigate("/");
    }

    if (!user) {
        return (
            <Link to="/login">
                <Button
                    variant="solid"
                    size="sm"
                >
                    {isMobile() ? "Login" : "Login/Register"}
                </Button>
            </Link>
        )
    }
    else {
        return (
            <Menu>
                <MenuButton as={IconButton}
                    aria-label="User Settings"
                    variant="solid"
                    icon={ user.photoURL ?
                            <Avatar size="sm" showBorder borderColor="gray.400"
                            name={user.displayName} src={user.photoURL} /> :
                            <MdPerson size={30} /> }
                    //onClick={onOpen}
                    color={user.photoURL ? "transparent" : "black"}
                    //bgColor={user.photoURL ? "transparent" : "white"}
                />
                <Portal>
                    <MenuList bg="grey">
                        <Heading
                            ml={3}
                            size="xs"
                            visibility={user.displayName && 
                                        user.displayName.match(/\w/) ? "visible" : "hidden"}
                        >
                            Logged in as {user.displayName}
                        </Heading>
                        <MenuDivider/>
                        <Link to="/customer/profile">
                            <MenuItem>Profile</MenuItem>
                        </Link>
                        <Link to="/customer/history">
                            <MenuItem>Order History</MenuItem>
                        </Link>
                        <MenuItem onClick={logoutHandler}>Logout</MenuItem>
                    </MenuList>
                </Portal>
            </Menu>
        )
    }
}


export default User;