import React from "react";
import { navigate } from "gatsby";
import {
    Box,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    Text,
    Textarea,
    Stack,
    Button,
    IconButton, 
    Circle,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    Spacer,
    Progress,
    Heading,
    Flex,
    useDisclosure,
    useInterval
} from "@chakra-ui/react";
import { MdShoppingCart, MdChevronLeft, MdChevronRight } from "react-icons/md";
import { RiDeleteBin6Line, RiCheckboxBlankLine, RiCheckboxFill } from "react-icons/ri";

import CCCheckout from "./checkout_cc";
import PGCheckout from "./checkout_pg";
import TermsConditions from "./tnc";
import OrderPolicy from "./policy";

import { useHasMounted } from "./hooks";

import getFirebase from "../utils/firebase";
import { vitalizeConstants } from "../utils/constants";
import * as utils from "../utils/utility_funcs";
import UserMgr from "../utils/user_manager";
const UMObj = UserMgr.getInstance();


const SpecialInstrBox = (props) => {  
    const [tooLong, setTooLong] = React.useState(false);

    const handleInputChange = (event) => {
        const inputVal = event.target.value;
        setTooLong(inputVal.length > 150);
        const inputValTrunc = inputVal.slice(0, 150);
        props.handler(inputValTrunc);
    }

    return (
        <Textarea
            isInvalid={tooLong}
            value={props.value}
            onChange={handleInputChange}
            placeholder="Enter any special instructions here (max 150 characters)"
            size="sm"
            variant="filled"
        />
    )
}

const RemoveButton = (props) => {
    const hash = props.prodHash;

    const removeItem = () => {
        console.log("REMOVING ITEM " + hash);
        UMObj.CartRemove(null, hash);
        props.handleChange(UMObj.GetCart());
    }

    return (
        <IconButton
            variant="ghost"
            size="xs" 
            icon={<RiDeleteBin6Line size={25}/>}
            colorScheme="red"
            onClick={removeItem}
        />
    )
}

const QuantChange = (props) => {
    const hash = props.prodHash;
    const isDec = props.isDecrease;
    const moq = props.moq;
    const newQuant = isDec ? props.currentQuant - 1 : props.currentQuant + 1;

    const changeHandle = () => {
        UMObj.CartRemove(null, hash);
        var prod = {};
        prod.fromHistory = true;
        prod.id = hash;
        prod.quantity = newQuant;
        const success = UMObj.CartAdd(prod);
        console.log("NEW QUANT " + newQuant);
        if (!success) console.log("CANNOT ADD CART QUANT ITEMS");
        props.handleChange(UMObj.GetCart());
    }

    return (
        <IconButton
            variant="ghost"
            size="xs" 
            isDisabled={newQuant < moq}
            icon={isDec ? <MdChevronLeft size={25}/> : <MdChevronRight size={25}/>}
            onClick={changeHandle}
        />
    )
}

const CartContents = (props) => {
    const [cart, setCart] = React.useState(UMObj.GetCart());
    const [checked, setChecked] = React.useState(false);
    const [specialInstr, setSpecialInstr] = React.useState("");
    const [thanksMsg, setThanksMsg] = React.useState(false);

    var cartSum = 0;
    var rowSum = [];
    var analyticsProdList = [];
    for (const item of cart) {
        var itemPrice = item[2] * item[3];
        itemPrice = parseFloat(itemPrice.toFixed(2));
        rowSum.push(itemPrice);
        cartSum += itemPrice;
        analyticsProdList.push(utils.getAnalyticsProduct(item[4], item[0], item[5], itemPrice, item[2]))
    }
    cartSum = parseFloat(cartSum.toFixed(2));
    const cartSumStr = utils.formatPrice(cartSum);
    const rowTotals = rowSum.map(rs => utils.formatPrice(rs).split(" ")[0]);
    
    var limFraction = cartSum / vitalizeConstants.DELIVERY_THRES;
    const meetsLimit = limFraction > 1;
    var amountLeft = vitalizeConstants.DELIVERY_THRES - cartSum;
    amountLeft = amountLeft.toFixed(2);
    const amountLeftStr = utils.formatPrice(amountLeft);
    const blurb = meetsLimit ?
        `Congrats, you get free delivery!` :
        `Add ${amountLeftStr} for free delivery, current total: ${cartSumStr}`;
    limFraction = (limFraction > 1) ? 100 : parseInt(limFraction * 100);

    const cartDelivFee = meetsLimit ? 0 : vitalizeConstants.DELIVERY_FEE;
    const cartDelivFeeStr = cartDelivFee ? utils.formatPrice(cartDelivFee) : "Free!";

    var taxAmt = utils.getTax(cartSum);
    taxAmt = parseFloat(taxAmt.toFixed(2));
    const taxAmtStr = utils.formatPrice(taxAmt);

    var totalSum = utils.getTaxedPrice(cartSum) + cartDelivFee;
    totalSum = parseFloat(totalSum.toFixed(2));
    const totalSumStr = utils.formatPrice(totalSum);
    
    // Log customer viewing cart
    UMObj.AnaLog("view_cart", {
        currency: 'AED',
        value: totalSum,
        items: analyticsProdList
    })

    var supportedEmList = "";
    vitalizeConstants.SUPPORTED_EMIRATES.forEach((emi, i) => {
        supportedEmList += emi;
        if (i !== vitalizeConstants.SUPPORTED_EMIRATES.length - 1) supportedEmList += ", ";
    });

    const CCSuccess = () =>  {
        return (
            <Box display={!cart.length && thanksMsg ? "inherit" : "none"}>
                <Text fontSize="md">Thank you for your order! <span role="img" aria-label={"Party emoji"}>🎉</span></Text>
                <br></br>
                <Text fontSize="md">You will receive an email shortly <span role="img" aria-label={"Email emoji"}>✉️</span></Text>
                <br></br>
                <Text fontSize="md">We are redirecting you back...</Text>
            </Box>
        )
    }

    return (
        <>
        <Progress
            mx={{base: 2, sm: 4}}
            mt={6}
            size="md"
            bgColor="grey"
            colorScheme={meetsLimit ? "green" : "orange"}
            value={limFraction}
        />
        <Text
            mx={{base: 2, sm: 4}}
            fontSize={{base:"sm", sm: "md"}}
            textColor={meetsLimit ? "green" : "orange"}
        >
            <b>{blurb}</b>
        </Text>
        <Box mt={6} />
        <hr />
        <Stack
            mx={{base: 2, sm: 4}} mt={6} w="100%"
            direction="row"
            spacing={4}
            display={!cart.length ? "inherit" : "none"}
        >
            <Box display={!cart.length && !thanksMsg ? "inherit" : "none"}>
                <Text fontSize="md">Cart is empty <span role="img" aria-label={"Sad empty cart emoji"}>🛒</span></Text>
            </Box>

            <CCSuccess />
        </Stack>
        
        <Box
            display={cart.length ? "inherit" : "none"}
            bgColor="blanchedalmond"
            borderWidth="1px"
            borderRadius="lg"
        >
            <Table variant="simple" size="sm">
                <TableCaption>
                    <Flex
                        display={!props.userConfirm ? "flex" : "none"}
                        mt={2}
                        w="100%"
                        alignItems="center"
                        direction="column"
                    >
                        <Text>
                            To checkout with us, please update your Profile to confirm email and phone number.
                            If you plan to pay using card/cash on delivery, please enter a delivery address in your Profile.
                        </Text>
                        <Text>
                            Currently we deliver to the following Emirates: <br></br>
                            {supportedEmList}
                        </Text>
                        <Button
                            mt={2}
                            onClick={() => navigate("/customer/profile")}
                        >
                            Go to Profile
                        </Button>
                    </Flex>

                    <Flex
                        display={props.userConfirm ? "flex" : "none"}
                        mt={2}
                        w="100%"
                        alignItems="center"
                        direction="column"
                    >
                        <SpecialInstrBox value={specialInstr} handler={setSpecialInstr} />

                        <Flex mt={2} direction="row">
                            <IconButton
                                aria-label="I accept the Terms and Conditions and Order Policy"
                                variant="unstyled"
                                icon={checked ?
                                    <RiCheckboxFill size={25} color="black"/> :
                                    <RiCheckboxBlankLine size={25} color="black" />}
                                onClick={() => setChecked(!checked)}
                            />
                            <Text fontSize="md">I accept the <TermsConditions /> and <OrderPolicy /></Text>
                        </Flex>

                        <PGCheckout
                            disabled={!checked}
                            cart={cart}
                            addDelivFee={!meetsLimit}
                            specialInstr={specialInstr}
                        />
                        
                        <CCCheckout
                            disabled={!checked}
                            cart={cart}
                            addDelivFee={!meetsLimit}
                            subTotal={cartSumStr}
                            taxAmt={taxAmtStr}
                            delivFee={cartDelivFeeStr}
                            rowTotals={rowTotals}
                            total={totalSumStr}
                            specialInstr={specialInstr}
                            handleChange={setCart}
                            handleMessage={setThanksMsg}
                        />
                    </Flex>
                </TableCaption>

                <Thead>
                    <Tr>
                    <Th>Product</Th>
                    <Th>Pack Size</Th>
                    <Th><Box align="center">Qty</Box></Th>
                    <Th>Price</Th>
                    <Th>Total</Th>
                    <Th></Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {cart.map((item, i) => 
                        <Tr mt={15} key={utils.hashCode(item[0] + item[1] + item[2])}>
                            <Td>{item[0].split("/")[0]}</Td>
                            <Td>{item[1]}</Td>
                            <Td>
                                <Flex direction="row">
                                    <QuantChange
                                        key={utils.hashCode(item[0] + item[1] + item[2] + "dec_" + i)}
                                        prodHash={item[4]}
                                        isDecrease={true}
                                        currentQuant={item[2]}
                                        handleChange={setCart}
                                        moq={item[7]}
                                    />
                                    <Text pt={1}>{item[2]}</Text>
                                    <QuantChange
                                        key={utils.hashCode(item[0] + item[1] + item[2] + "inc_" + i)}
                                        prodHash={item[4]}
                                        isDecrease={false}
                                        currentQuant={item[2]}
                                        handleChange={setCart}
                                        moq={item[7]}
                                    />
                                </Flex>
                            </Td>
                            <Td>{utils.formatPrice(item[3]).split(" ")[0]}</Td>
                            <Td>
                                <Stack
                                    direction="row"
                                    spacing={1}
                                >
                                    <Text pt={1} fontSize="sm">{utils.formatPrice(rowSum[i]).split(" ")[0]}</Text>
                                    <RemoveButton
                                        key={utils.hashCode(item[0] + item[1] + item[2])}
                                        prodHash={item[4]}
                                        handleChange={setCart}
                                    />
                                </Stack>
                            </Td>
                        </Tr>
                    )}
                </Tbody>
                <Tfoot>
                    <Tr>
                    <Th>Subtotal before VAT</Th>
                    <Th></Th>
                    <Th></Th>
                    <Th></Th>
                    <Th isNumeric>{cartSumStr}</Th>
                    </Tr>
                    <Tr>
                    <Th>VAT ({vitalizeConstants.VAT_AMOUNT}%)</Th>
                    <Th></Th>
                    <Th></Th>
                    <Th></Th>
                    <Th isNumeric>{taxAmtStr}</Th>
                    </Tr>
                    <Tr>
                    <Th>Delivery Fee</Th>
                    <Th></Th>
                    <Th></Th>
                    <Th></Th>
                    <Th isNumeric>{cartDelivFeeStr}</Th>
                    </Tr>
                    <Tr>
                    <Th>Total Amount incl. VAT</Th>
                    <Th></Th>
                    <Th></Th>
                    <Th></Th>
                    <Th isNumeric>{totalSumStr}</Th>
                    </Tr>
                </Tfoot>
            </Table>
        </Box>
        </>
    );
};

const ShoppingCart = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = React.useRef();
    const [cartSize, setCartSize] = React.useState(UMObj.GetCartSize());
    const [userConfirmed, setUserConfirmed] = React.useState(false);
    const [fsModule, setFSModule] = React.useState(null);
    const [fs, setFS] = React.useState(null);

    useInterval(() => setCartSize(UMObj.GetCartSize()), 500);

    // Check for profile completeness
    useInterval(async () => {
        if (fs && fsModule) {
            const currUser = UMObj.GetCurrentUser();
            if (!currUser) {
                setUserConfirmed(false);
                return;
            }
            const docRef = fsModule.doc(fs, "users", currUser.uid);
            const userDB = await fsModule.getDoc(docRef)
            .then(snap => {
                if (snap.exists() && snap.data()) return snap.data();
                return null;
            });

            if (!userDB || !userDB.email || !userDB.emailVerified
                    || !userDB.phone || !userDB.phoneVerified)
            {
                console.log("USER PROFILE NEEDS UPDATING");
                setUserConfirmed(false);
            }
            else setUserConfirmed(true);
        }
    }, 2000);

    const hasMounted = useHasMounted();

    if (hasMounted) {
        var fbImportPromises = [];
        fbImportPromises.push(import("firebase/app"));
        fbImportPromises.push(import("firebase/auth"));
        fbImportPromises.push(import("firebase/firestore"));
      
        Promise.all(fbImportPromises)
        .then(([fbAppModule, fbAuthModule, fbFSModule]) => {
            const fb = getFirebase(fbAppModule);
            const fbApp = fb.getApp();
            const auth = fbAuthModule.getAuth(fbApp);
            fbAuthModule.useDeviceLanguage(auth);
            fbAuthModule.onAuthStateChanged(auth, UMObj.AuthListener);
            fbAuthModule.onIdTokenChanged(auth, UMObj.AuthListener);
            const fs = fbFSModule.getFirestore(fbApp);

            setFSModule(fbFSModule);
            setFS(fs);
        })
    }

    return (
        <>
        <Box pos="relative">
            <IconButton 
                aria-label="Cart"
                variant="solid"
                icon={ <MdShoppingCart size={30} /> }
                onClick={onOpen}
                color="black"
            />
            <Circle
                visibility={cartSize > 0 ? "visible" : "hidden"}
                size="20px"
                bg="#ffcb02"
                color="white"
                zIndex={vitalizeConstants.Z_CARTICON}
                pos="absolute" top="-2" right="-1">
                <Box as="span" fontWeight="bold" fontSize="md" color="black">
                    {cartSize}
                </Box>
            </Circle>
        </Box>
        <Drawer
            blockScrollOnMount={false}
            placement="right"
            onClose={onClose}
            isOpen={isOpen}
            finalFocusRef={btnRef}
            size="lg"
        >
            <DrawerOverlay>
                <DrawerContent>
                <DrawerHeader borderBottomWidth="1px">
                    <Flex direction="row">
                        <Button
                            size="sm"
                            variant="solid"
                            leftIcon={<MdChevronLeft size={20}/>}
                            bgColor="blue.200"
                            onClick={onClose}
                        >
                            Continue Shopping
                        </Button>
                        <Spacer />
                        <Heading size={{base: "md", sm: "lg"}}>Cart</Heading>
                    </Flex>
                </DrawerHeader>
                <DrawerBody p={0}>
                    <CartContents userConfirm={userConfirmed}/>
                </DrawerBody>
                </DrawerContent>
            </DrawerOverlay>
        </Drawer>
        </>
    );
};

export default ShoppingCart;