import React from "react";
import {Box, Button } from "@chakra-ui/react";
import { Link, navigate } from "gatsby";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useHasMounted } from "../components/hooks";

import getFirebase from "../utils/firebase";
import { vitalizeConstants } from "../utils/constants";
import { getAnalyticsProduct } from "../utils/utility_funcs";
import UserMgr from "../utils/user_manager";
const UMObj = UserMgr.getInstance();


const CCCheckout = (props) => {
    const cart = props.cart;
    const subTotal = props.subTotal;
    const taxAmt = props.taxAmt;
    const delivFee = props.delivFee;
    const rowTotals = props.rowTotals;
    const total = props.total;
    const specialInstr = props.specialInstr;
    console.log(props);

    const [loading, setLoading] = React.useState(false);
    const [fsModule, setFSModule] = React.useState(null);
    const [fs, setFS] = React.useState(null);

    const hasMounted = useHasMounted();

    const getProfile = async (uid) => {
        const docRef = fsModule.doc(fs, "users", uid);
        const user = await fsModule.getDoc(docRef)
        .then(snap => {
            if (snap.exists() && snap.data()) return snap.data();
            return null;
        });

        if (!user || !user.email || !user.emailVerified
                  || !user.phone || !user.phoneVerified
                  || !user.adline1 || !user.emirate)
        {
            console.log("USER ERROR IN CHECKOUT CC");
            return null;
        }
        return user;
    }

    const startCCCheckout = async () => {
        setLoading(true);

        const currUser = UMObj.GetCurrentUser();

        // If user is not authed, or email not verified
        if (!currUser) {
            const LoginLink = () => <Box>Please <Link to="/login"><u>Login/Register</u> to use our payment-on-delivery option</Link></Box>;
            toast.error(LoginLink, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setLoading(false);
            return;
        }
        
        if (fs && fsModule && currUser) {
            const userProfile = await getProfile(currUser.uid);
            if (!userProfile) {
                const ProfileLink = () => <Box><Link to="/profile">Please verify your <u>Profile</u> to use our payment-on-delivery option</Link></Box>;
                toast.error(ProfileLink, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setLoading(false);
                return;
            }

            // Filter out individual columns from cart because FS does not support nested arrays
            // [p.name, p.unit, p.quantity, utils.getDiscountedPrice(parseFloat(p.price), p.discount), entry[0], p.category, p.thumb]);
            var cartItems = [];
            cart.forEach((item, i) => {
                cartItems.push({
                    thumb: "https:" + item[6],
                    name: item[0].split("/")[0],
                    unit: item[1],
                    quantity: item[2],
                    price: item[3],
                    total: rowTotals[i]
                });
            });
            console.log(cartItems);
            if(!cartItems.length) {
                console.error("EMPTY CART CC");
                return;
            }

            // OK, we have a verified user placing an order
            // Send email confirmation to customer
            const mailColl = fsModule.collection(fs, "mail");
            fsModule.addDoc(mailColl, {
                toUids: [currUser.uid],
                bcc: vitalizeConstants.RECIPIENT_BCC,
                // message: {
                //     subject: 'VitalizeFresh Order Confirmation',
                //     //text: 'This is the plaintext section of the email body.',
                //     html: `This is the cart: <code>${JSON.stringify(cart)}</code>`,
                // }
                template: {
                    name: "orderconf",
                    data: {
                        logoPath: process.env.URL_MAIN + "vitalize_logo_cropped.png",
                        checkoutPG: false,
                        name: userProfile.name,
                        phone: userProfile.phone,
                        adline1: userProfile.adline1,
                        adline2: userProfile.adline2,
                        emirate: userProfile.emirate,
                        // Cart items by column
                        cartItems: cartItems,
                        // Calculated totals
                        subTotal: subTotal,
                        taxAmt: taxAmt,
                        delivFee: delivFee,
                        total: total,
                        // Special instruction
                        specialInstr: specialInstr
                    },
                },
            })
            .then(docRef => {
                console.log("Email doc written with ID: ", docRef.id);
                UMObj.SetTransaction(null);
                UMObj.CartClear();

                // Logging orders for analytics
                var analyticsProdList = [];
                for (const item of cart) {
                    var itemPrice = item[2] * item[3];
                    itemPrice = parseFloat(itemPrice.toFixed(2));
                    analyticsProdList.push(getAnalyticsProduct(item[4], item[0], item[5], itemPrice, item[2]));
                }
                const transactionObject = {
                    transaction_id: docRef.id,
                    affiliation: "VitalizeFresh",
                    currency: 'AED',
                    value: props.total, // Total Revenue
                    tax: props.tax,
                    shipping: props.addDelivFee ? vitalizeConstants.DELIVERY_FEE : 0,
                    items: analyticsProdList,
                    paymentMethod: "cash_card",
                    specialInstr: specialInstr
                };
                UMObj.SetTransaction(transactionObject);

                // Force refresh
                setLoading(false);
                props.handleMessage(true);
                props.handleChange(UMObj.GetCart());
                const CCSuccess = `Thank you for your order! 🎉
                    You will receive an email shortly ✉️
                    We are redirecting you back...`;
                toast.success(CCSuccess, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setTimeout(() => navigate(process.env.URL_PAYMENT_SUCCESS), 7000);
            })
            .catch(err => {
                console.log("Error writing mail doc: ", err);
                toast.error(err, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setLoading(false);
            })
        }
    }

    if (hasMounted) {
        var fbImportPromises = [];
        fbImportPromises.push(import("firebase/app"));
        fbImportPromises.push(import("firebase/auth"));
        fbImportPromises.push(import("firebase/firestore"));
      
        Promise.all(fbImportPromises)
        .then(([fbAppModule, fbAuthModule, fbFSModule]) => {
            const fb = getFirebase(fbAppModule);
            const fbApp = fb.getApp();
            const auth = fbAuthModule.getAuth(fbApp);
            fbAuthModule.useDeviceLanguage(auth);
            fbAuthModule.onAuthStateChanged(auth, UMObj.AuthListener);
            fbAuthModule.onIdTokenChanged(auth, UMObj.AuthListener);
            const fs = fbFSModule.getFirestore(fbApp);

            setFSModule(fbFSModule);
            setFS(fs);
        })
    }

    return (
        <>
        <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
        <Button
            isDisabled={props.disabled}
            mt={2}
            w="min-content"
            isLoading={loading}
            onClick={startCCCheckout}
            textColor="gray.800"
            bgColor="goldenrod"
        >
            Cash/Card on Delivery
        </Button>
        </>
    )
}

export default CCCheckout;
