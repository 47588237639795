import React from "react";
import { Link, navigate } from "gatsby";
import {
    Box,
    Flex,
    Img,
    Text,
    Center,
    Input,
    InputGroup,
    InputRightElement,
    Popover,
    PopoverHeader,
    PopoverCloseButton,
    PopoverContent,
    PopoverTrigger,
    PopoverArrow,
    PopoverBody,
    Table,
    Tbody,
    Tr,
    Td,
} from "@chakra-ui/react";
import { MdSearch } from "react-icons/md";
import * as JsSearch from 'js-search';

import * as utils from "../utils/utility_funcs";
import UserMgr from "../utils/user_manager";
const UMObj = UserMgr.getInstance();

const SearchBar = () => {
    const focusRef = React.useRef();

    const searchIndex = UMObj.GetProductList();
    var search = new JsSearch.Search('contentful_id');
    search.addIndex('name')
    search.addIndex(['categoryAndSubCategory', 'categoryName']);
    search.addIndex(['categoryAndSubCategory', 'subCategoryName']);
    search.addDocuments(searchIndex);

    const [queryResults, updateQuery] = React.useState([]);
    const [isNotFound, setIsNotFound] = React.useState(false);
    const [active, setActive] = React.useState(0);

    // Keyboard listener
    const onKeyDown = React.useCallback(e => {
        switch (e.key) {
            case "ArrowDown":
                e.preventDefault();
                if (active + 1 < queryResults.length && queryResults.length) {
                    const newActive = active + 1;
                    const elem = document.getElementById("sr_" + newActive);
                    document.body.style.position = "fixed";
                    elem.scrollIntoView();    
                    document.body.style.position = "";
                    setActive(newActive);
                }
                break;

            case "ArrowUp":
                e.preventDefault();
                if (active - 1 >= 0 && queryResults.length) {
                    const newActive = active - 1;
                    const elem = document.getElementById("sr_" + newActive);
                    document.body.style.position = "fixed";
                    elem.scrollIntoView();    
                    document.body.style.position = "";
                    setActive(newActive);
                }
                break;

            case "Enter":
                e.preventDefault();
                const prodName = queryResults[active].name;
                navigate("/products/" + utils.removeWS(prodName));
                break;

            default: break;
        }
    }, [active, queryResults]);

    const onKeyUp = React.useCallback(e => {
    }, []);
    
    const handleChange = (event) => {
        const searchStr = event.target.value;
        popOpen();
        const queryResults = search.search(searchStr);
        const sortedResults = queryResults.sort((a, b) => {
            const lenA = a.name.length;
            const lenB = b.name.length;
            return lenA - lenB;
        });
        updateQuery(sortedResults);
        if (searchStr === "") setIsNotFound(false);
        else if (!queryResults.length) setIsNotFound(true);
        else setIsNotFound(false);
    }

    const [isPopOpen, setPopOpen] = React.useState(false)
    const popOpen = () => setPopOpen(true)
    const popClose = () => setPopOpen(false)

    return (
        <Center pl={2} width="100%" maxW={{base: "8em", sm: "20em", md: "30em"}}>
            <Popover
                isLazy
                placement="bottom"
                initialFocusRef={focusRef}
                isOpen={isPopOpen}
                onClose={popClose}
            >
                <PopoverTrigger>
                    <InputGroup>
                    <Input
                        ref={focusRef}
                        placeholder="Search"
                        onClick={popOpen}
                        onChange={handleChange}
                        onKeyDown={onKeyDown}
                        onKeyUp={onKeyUp}
                        bgColor="whiteAlpha.600"
                    />
                    <InputRightElement children={<MdSearch color="blackAlpha" size={30} />} />
                    </InputGroup>
                </PopoverTrigger>
                <PopoverContent>
                    <PopoverHeader
                        fontWeight="semibold"
                        fontSize="xs"
                        textTransform="uppercase"
                    >
                        Search Results
                    </PopoverHeader>
                    <PopoverCloseButton />
                    <PopoverArrow />
                    <PopoverBody>
                    {isNotFound ? 
                    <Text>Sorry, this product was not found <span role="img" aria-label={"Product not found"}>😔</span></Text> :
                    <Box overflow="auto" maxH="lg">
                        <Table size="md">
                            <Tbody>
                                {Object.entries(queryResults).map((result, i) => (
                                    <Tr
                                        id={"sr_" + i}
                                        key={"sr_" + i}
                                        bgColor={active === i ? "blue.300" : "gray.200"}
                                    >
                                        <Td>
                                            <Box >
                                            <Link to={"/products/" + utils.removeWS(result[1].name)}>
                                                <Flex direction="row">
                                                    <Img maxW="20%" src={result[1].imageThumb.file.url} />
                                                    <Box ml={3}>
                                                        {result[1].name}
                                                    </Box>
                                                </Flex>
                                            </Link>
                                            </Box>
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </Box>
                    }
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </Center>
    );
};

export default SearchBar;