
const catMap = {
    "Fresh Juices" : ["Fruit Juices", "Healthy Juices", "Smoothies", "Ramadan Juices"],
    "Readymade Meals" : ["Filipino Meals", "Italian Meals", "Indian Meals", "Chinese Meals"],
    "Fresh Fruits" : ["Whole Fruits", "Washed Fruits", "Fruit Baskets", "Cut Fruits", "Cut Fruit Platters"],
    "Fresh Vegetables" : ["Whole Vegetables", "Cut & Sanitized Vegetables"],
    "Cakes" : ["Celebration Cakes", "Frozen Cakes"],
    "Bakery" : ["Bakery Ambient & Chilled", "Bakery Frozen", "Dairy & Eggs"],
    "Ready to Eat" : ["Sandwiches & Wraps", "Salads"],
    "Condiments" : ["Purees & Sauces", "Dressings & Dips"]
};

export default catMap;