import React from "react";
import {
    Box,
    Flex, 
    HStack,
    VStack,
    Spacer,
    Center,
    Wrap,
    WrapItem,
    Button,
    IconButton,
    Grid,
    Heading,
    Collapse,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Popover,
    PopoverTrigger,
    PopoverContent,  
    useDisclosure
} from "@chakra-ui/react";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";

// Category Icons
import { MdMenu } from "react-icons/md";
import { 
    GiManualJuicer,
    GiNoodles,
    GiStrawberry,
    GiSlicedBread,
    GiSandwich,
    GiApothecary,
} from "react-icons/gi"
import { FaCarrot, FaBirthdayCake } from "react-icons/fa";
import { BiPlusCircle, BiMinusCircle } from "react-icons/bi";

import Logo from "./logo";
import SearchBar from "./search";
import User from "./user";
import ShoppingCart from "./cart";

import catMap from "../../bin/category_map_es6";

import * as utils from "../utils/utility_funcs";
import UserMgr from "../utils/user_manager";
const UMObj = UserMgr.getInstance();


// TODO: remove this in production, we are back to this due to a bug
const test = true;
// TODO: fix category list bug
const iconList = {
    "Fresh Juices" : <GiManualJuicer size={30}/>,
    "Readymade Meals" : <GiNoodles size={30}/>,
    "Fresh Fruits" : <GiStrawberry size={30}/>,
    "Fresh Vegetables" : <FaCarrot size={25}/>,
    "Cakes" : <FaBirthdayCake size={25}/>,
    "Bakery" : <GiSlicedBread size={30}/>,
    "Ready to Eat" : <GiSandwich size={30}/>,
    "Condiments" : <GiApothecary size={30}/>
}

const ProductCategoryMenuMobile = () => {
    var cats;
    if (test) cats = Object.keys(catMap);
    else cats = UMObj.GetCategoryList();

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [show, setShow] = React.useState(new Array(cats.length).fill(false));
    const drawerBtnRef = React.useRef();

    const SubCatCollapse = (props) => {    
        const idx = props.idx;
        const subCats = catMap[cats[idx]];
        return (
            <Collapse in={show[idx]} animateOpacity>
                <Flex
                    rounded={3}
                    px={3}
                    py={3}
                    w="90%"
                    direction="column"
                    bgColor="gray.200"
                >
                    {subCats.map(subCat => 
                        <AnchorLink
                            key={"subCat_mob" + idx}
                            to={"/categories/" + utils.removeWS(cats[idx]) + "#" + utils.removeWS(subCat)}>
                            <Heading mt={2} size="md"><u>{subCat}</u></Heading>
                        </AnchorLink>
                    )}
                </Flex>
            </Collapse>
        )
    }

    return (
        <Center>
            <IconButton
                display={{md: "none"}}
                aria-label="Product Category Menu"
                variant="ghost"
                icon={ <MdMenu size={25} /> }
                onClick={onOpen}
                color="black"
                size="sm"
            />
            <Drawer 
                placement="left"
                onClose={onClose}
                isOpen={isOpen}
                finalFocusRef={drawerBtnRef}
                size="md"
            >
                <DrawerOverlay>
                    <DrawerContent>
                    <DrawerHeader borderBottomWidth="1px">Product Categories</DrawerHeader>
                    <DrawerCloseButton variant="outline"/>
                    <DrawerBody mt={6}>
                    <Grid gap={6}>
                        {cats.map((c, i) => (
                            <Flex key={c+"_mbkey_"+i} direction="column">
                                <Flex direction="row" w="100%">
                                    <Box w="90%">
                                        <Link to={"/categories/" + utils.removeWS(c)} >
                                            <Button
                                                variant="solid"
                                                leftIcon={iconList[c]}
                                                bgColor="blue.200"
                                                w="100%"
                                            >
                                                {c}
                                            </Button>
                                        </Link>
                                    </Box>
                                    <Box w="10%">
                                        <IconButton
                                            variant="ghost"
                                            icon={show[i] ? <BiPlusCircle size={35} /> : <BiMinusCircle size={35} />}
                                            onClick={() => {
                                                const newShow = show.map((s, idx) => {
                                                    if (idx === i) return !s;
                                                    else return s;
                                                });
                                                setShow(newShow);
                                            }}
                                        />
                                    </Box>
                                </Flex>
                                <SubCatCollapse idx={i} />
                            </Flex>
                        ))}
                    </Grid>
                    </DrawerBody>
                    </DrawerContent>
                </DrawerOverlay>
            </Drawer>
        </Center>
    );
}

const ProductCategoryMenuDesktop = () => {
    var cats;
    if (test) cats = Object.keys(catMap);
    else cats = UMObj.GetCategoryList();

    const SubCatPopContent = (props) => {    
        const idx = props.idx;
        const subCats = catMap[cats[idx]];

        return (
            <Flex
                key={"subCat_desk" + idx}
                w="max-content"
                rounded={3}
                px={3}
                py={3}
                direction="column"
                bgColor="gray.200"
            >
                {subCats.map((subCat, i) => 
                    <AnchorLink key={ "sckey_"+subCat+i } to={"/categories/" + utils.removeWS(cats[idx]) + "#" + utils.removeWS(subCat)}>
                        <Heading mt={2} size="sm"><u>{subCat}</u></Heading>
                    </AnchorLink>
                )}
            </Flex>
        )
    }

    return (
        <Wrap spacing={{base: "1.5", md: "3"}}>
            {cats.map((c, i) => (
                <WrapItem key={ c+"_key_"+i }>
                    <Popover isLazy trigger="hover">
                        <PopoverTrigger>
                            <Link to={"/categories/" + utils.removeWS(c)} >
                                <Button
                                    variant="solid"
                                    leftIcon={iconList[c]}
                                    bgColor="blue.200"
                                >
                                    {c}
                                </Button>
                            </Link>
                        </PopoverTrigger>
                        <PopoverContent w="max-content">
                            <SubCatPopContent key={ c+"_key_"+i } idx={i}/>
                        </PopoverContent>
                    </Popover>
                </WrapItem>
            ))}
        </Wrap>
    );
}; 

const NavBar = () => {
    return (
        <VStack spacing={0}>
            <Flex
                bgGradient="linear(to-l, green.400, gray.300)"
                px={2}
                py={{base: "2", md: "2.5"}}
                w="100%"
            >
                <ProductCategoryMenuMobile/>
                <Logo />
                <Spacer />
                <SearchBar />
                <HStack ml={{ base: "2", sm: "4"}}>
                    <User />
                    <ShoppingCart />
                </HStack>
            </Flex>
            <Center
                bg="teal.500"
                w="100%"
                display={{base: "none", md: "flex"}}
            >
                <Flex
                    bg="teal.500"
                    px={2}
                    py={{base: "2", md: "4"}}
                >
                    <ProductCategoryMenuDesktop />
                </Flex>
            </Center>
        </VStack>
    );
};

export default NavBar;
