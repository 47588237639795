import React from "react";
import { Button } from "@chakra-ui/react";
import { graphql, useStaticQuery } from "gatsby";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import getStripe from "../utils/stripejs";
import { removeWS, hashCode } from "../utils/utility_funcs";
import { vitalizeConstants } from "../utils/constants";
import UserMgr from "../utils/user_manager";
const UMObj = UserMgr.getInstance();


const PGCheckout = (props) => {
    const [loading, setLoading] = React.useState(false);
    const cart = props.cart;

    const data = useStaticQuery(graphql`
    query {
        allStripePrice {
            edges {
                node {
                    id
                    product {
                        id
                        name
                    }
                    metadata {
                        packUnit
                        deliv
                    }
                }
            }
        }
        allStripeTaxRate {
            nodes {
                id
            }
        }
    }
    `);

    // Get tax rates
    const taxID = data.allStripeTaxRate.nodes[0].id;

    // Add products to line
    var delivID = null;
    var lineList = [];
    var prodDesc = {};
    for (const item of cart) {
        const itemNameSpaced = item[0];
        const itemName = removeWS(item[0]);
        const itemID = item[4].split("_")[0];
        const itemPU = item[4].split("_")[1];
        const itemQuant = item[2];
        for (const { node: price } of data.allStripePrice.edges) {
            //console.log(price);
            const pgID = price.product.id;
            const pgName = removeWS(price.product.name);

            // If special price delivery fee detected, metadata is null
            if (price.metadata.deliv) {
                delivID = price.id;
            }
            else {
                const pgPU = hashCode(price.metadata.packUnit);
                if(pgID === itemID && pgName === itemName && parseFloat(pgPU) === parseFloat(itemPU)) {
                    // Push line item
                    lineList.push({ price: price.id, quantity: itemQuant, tax_rates: [taxID] });
                    // Push description for server
                    prodDesc[price.id] = [itemNameSpaced, item[5], item[4]];
                    // Error check
                    if (!prodDesc[price.id] || prodDesc[price.id].length !== vitalizeConstants.ARRAY_LEN_PROD_DESC) {
                        console.log("CHECK PG PROD: ", prodDesc[price.id]);
                    }
                }
            }
        }
    }

    // Add delivery fee if cart total does not meet limit
    if (props.addDelivFee && cart.length > 0) {
        if (delivID) lineList.push({ price: delivID, quantity: 1 });
        else console.error("DELIVERY FEE NOT IN PG PRICES");
    }

    const redirectToPGCheckout = async event => {
        event.preventDefault();
        setLoading(true);

        console.log("LINE_LIST:", lineList);
        console.log("PROD_DESC:", prodDesc);
        const stripe = await getStripe();

        // Post customer data to server and get PG checkout session from server
        var response = null;
        var session = null;
        const currUser = UMObj.GetCurrentUser();

        try {
            response = await fetch(process.env.URL_SERVER_CHECKOUT, {
                body: JSON.stringify({
                    email: (currUser && currUser.email && currUser.emailVerified) ? currUser.email : null,
                    clientRef: (currUser && currUser.uid) ? currUser.uid + "###" + props.specialInstr : null,
                    cart: lineList,
                    prodDesc: prodDesc
                }),
                headers: {
                    "Content-Type": "application/json"
                },
                method: "POST"
            });
            session = await response.json();
            console.log(response);
            console.log(session);
        }
        catch(err) {
            toast.error(`Server is down: ${err}, please try again later.`, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setLoading(false);
            return;
        }

        if (!session.id) {
            toast.error(`Server is down, please try again later.`, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setLoading(false);
            return;
        }
        else {
            if (!session.transaction) alert("NULL TRANSACTION AFTER CHECKOUT");
            session.transaction.paymentMethod = "stripe";
            session.transaction.specialInstr = props.specialInstr;
            UMObj.SetTransaction(session.transaction);
        }

        // When the customer clicks on the button, redirect them to Checkout.
        const result = await stripe.redirectToCheckout({
            sessionId: session.id,
        });
      
        if (result.error) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
            toast.error(`Server is down: ${result.error.message}, please try again later.`, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setLoading(false);
            return;
        }
    }

    return (
        <>
        <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
        <Button
            isDisabled={props.disabled}
            mt={2}
            w="min-content"
            isLoading={loading}
            onClick={redirectToPGCheckout}
            textColor="gray.800"
            bgColor="palevioletred"
        >
            Pay with Stripe
        </Button>
        </>
    )
}

export default PGCheckout;
